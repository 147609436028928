import React from 'react';
import Layout from '../../components/layout/Layout';
import Head from '../../components/layout/Head';
import FormList from '../../components/organisms/form-list/FormList';
import AuthWrapper from '../../components/layout/AuthWrapper';

const ListFormsPage = () => {
  return (
    <AuthWrapper>
      <Head title="Form Templates" />
      <Layout section="templates" breadcrumbs={[{ label: 'Form Templates' }]}>
        <FormList />
      </Layout>
    </AuthWrapper>
  );
};

export default ListFormsPage;
